import { LabeledValue } from 'antd/lib/select'

export default function handleSelectWithGroupings(
  fieldValue: string[],
  options: ({ itemGroupings?: number[] } & LabeledValue)[],
  value: string
) {
  let hasGroupedToSingleItem = false
  let nextValue = [...fieldValue]
  const newItemDef = options.find((item) => item.value === value)
  if (nextValue.length > 1) {
    // part 3.a handle multiple parts check
    if (newItemDef) {
      nextValue = nextValue.filter((newPart) => {
        if (value === newPart) return true
        const existingItemDef = options.find((item) => item.value === newPart)
        if (!existingItemDef) return false
        if (newItemDef.itemGroupings) {
          //new item has groupings - check whether existing item overlaps
          if (!existingItemDef.itemGroupings) return false
          let hasOverlap = false
          newItemDef.itemGroupings.forEach((itemGrouping) => {
            if ((existingItemDef.itemGroupings || []).indexOf(itemGrouping) > -1) {
              hasOverlap = true
            }
          })
          return hasOverlap
        } else if (existingItemDef.itemGroupings && existingItemDef.itemGroupings.length > 0) {
          //existing item has a grouping and new item does not - this implies existing item should be removed as there is no overlap
          return false
        } else {
          return true //no groupings
        }
      })
    }
  }
  if (nextValue.length === 1) {
    // part 3.b handle single parts check
    let hasAtLeastOneAdditionalInGroup = false
    options.forEach((existingItemDef) => {
      if (existingItemDef.value !== newItemDef?.value) {
        if (newItemDef?.itemGroupings?.length) {
          newItemDef.itemGroupings.forEach((itemGrouping) => {
            if (existingItemDef.itemGroupings && existingItemDef.itemGroupings.indexOf(itemGrouping) > -1) {
              hasAtLeastOneAdditionalInGroup = true
            }
          })
        } else {
          if (!existingItemDef.itemGroupings?.length) {
            hasAtLeastOneAdditionalInGroup = true
          }
        }
      }
    })
    if (!hasAtLeastOneAdditionalInGroup) {
      hasGroupedToSingleItem = true
    }
  }
  return { nextValue, hasGroupedToSingleItem }
}
