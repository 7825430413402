import { UnorderedListOutlined } from '@ant-design/icons'
import * as Sentry from '@sentry/nextjs'
import { ThumbsUp } from '@zeit-ui/react-icons'
import { Alert, Button, Dropdown, Form, FormInstance, FormProps, Input, Menu, notification, Radio, Select, Steps, StepsProps } from 'antd'
import Heading from 'components/heading'
import Loading from 'components/loading'
import AgreementLoading from 'components/loading/agreement-loading'
import LocaleSwitcher from 'components/locale-switch'
import SelectGrouped from 'components/select-grouped'
import { config } from 'config'
import getIntl, { setConfigI18NTexts } from 'i18n/config'
import { LocaleContext } from 'i18n/locale-provider'
import API, { ValidateUserDetailsResponse } from 'lib/api'
import { tz } from 'moment-timezone'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { memo, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { OPERATION_KEYS, VALIDATION_FAILURES } from 'typings/kenai/enums'
import { CHANNEL_CONFIG, ENTITY_CONFIG, USER_TYPES } from 'typings/kenai/interfaces'
import { ViewTypes } from 'typings/view-types'
import cn, { className } from 'utils/classnames'
import filterProcessor from '@kenai/utils.filter-processor'
import getScreenFields, { ScreenField } from 'utils/getScreenFields'
import removeLocationHash from 'utils/removeLocationHash'
import slugify from 'utils/slugify'
import useIsTouchDevice from 'utils/useIsTouchDevice'
import type { InstructionViewProps } from './instructions-view'
import type { WelcomeViewProps } from './welcome-view'

const WelcomeView = dynamic(() => import('./welcome-view'), {
  loading: AgreementLoading,
})

const NedbankInstructionsView = dynamic(() => import('./nedbank-instructions-view'), {
  loading: Loading,
})
const InstructionsView = dynamic(() => import('./instructions-view'), {
  loading: Loading,
})

function getInputElement(id: string) {
  return document.getElementById(`input-${id}`) as HTMLInputElement | undefined
}

const locationSlug = slugify('locationSelection')
const personalIDSlug = slugify('personalIdentificationNr')
const alternatePersonalIdNrSlug = slugify('alternatePersonalIdNr')
const orgIDSlug = slugify('organizationIdValue')

type EntityDetails = ValidateUserDetailsResponse['processingResponse']['entityDetails']
type ProcessInstanceRefs = Pick<ValidateUserDetailsResponse['processingResponse'], 'lmt' | 'processingToken'>

interface GroupedPreScreeningProps {
  onSSOIdentifiersMissing: () => void
  onEntitySelect: (entityHierarchy: string) => void
  selectionGrouping?: CHANNEL_CONFIG['appVariantSettings']['selectionGrouping']
  locations: ENTITY_CONFIG[]
  kioskMode: boolean
  userType: USER_TYPES
  tokenModeEnabled: boolean
  isSSOFlow: boolean
  step: ViewTypes
  welcomeViewProps?: WelcomeViewProps
  instructionsProps?: InstructionViewProps & { doneScreenHintSet?: 'NEDBANK' }
  textOverrides?: CHANNEL_CONFIG['appVariantSettings']['textOverrides']
}

export default function GroupedPreScreening(props: GroupedPreScreeningProps) {
  const intl = getIntl()
  const { setIntl, locale } = useContext(LocaleContext)
  const [loading, setLoading] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const { query } = useRouter()

  const [entityDetails, setEntityDetails] = useState<EntityDetails>(undefined)
  const [processInstanceRefs, setProcessInstanceRefs] = useState<ProcessInstanceRefs>(undefined)
  const [activeToken] = useState(props.tokenModeEnabled ? (query.token as string) : undefined)
  const [form] = Form.useForm()
  const [selectedEntityHierarchy, setSelectedEntity] = useState('')
  const touchDevice = useIsTouchDevice()
  const [interactionTimer, setInteractionTimer] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const [completedSteps, setCompletedSteps] = useState<('welcome' | 'authentication' | 'pre-screening' | 'instructions')[]>([])
  const [formState, setFormState] = useState<'' | 'isSubmitting' | 'isSubmitted'>('')
  const [instructionStatus, setInstructionStatus] = useState<'failed' | 'passed' | undefined>()
  const [authError, setAuthError] = useState('')
  const [submitError, setSubmitError] = useState('')

  const interactionTimerIntervalRef = useRef<NodeJS.Timeout>()
  const entityFieldTexts = useMemo(() => {
    const selectedLocation = !selectedEntityHierarchy
      ? props.locations?.[0]
      : props.locations.find((location) => location.EntityHierarchy === selectedEntityHierarchy)
    return selectedLocation.fieldTexts
  }, [selectedEntityHierarchy, props.locations])

  const useAlternatePersonalId = useMemo(() => {
    const selectedLocation = !selectedEntityHierarchy
      ? props.locations?.[0]
      : props.locations.find((location) => location.EntityHierarchy === selectedEntityHierarchy)
    return selectedLocation.useAlternatePersonalId
  }, [selectedEntityHierarchy, props.locations])

  useEffect(() => {
    if (props.kioskMode) {
      interactionTimerIntervalRef.current = setInterval(() => {
        setInteractionTimer((c) => c + 1)
      }, 1000)
    }
    removeLocationHash()
    return () => {
      if (props.kioskMode) {
        clearInterval(interactionTimerIntervalRef.current)
      }
      removeLocationHash()
    }
  }, [props.kioskMode])

  useEffect(() => {
    if (interactionTimer > 60) {
      window.location.reload()
    }
  }, [interactionTimer])

  useEffect(() => {
    // If not multi tenant set first entity as selected
    if (!props.selectionGrouping) {
      if (props.locations.length === 1) {
        setSelectedEntity(props.locations[0].EntityHierarchy)
      } else {
        if (!props.isSSOFlow) {
          const localEntityHierarchy = localStorage.getItem('precheck-EntityHierarchy')
          if (localEntityHierarchy) {
            props.locations.some((location) => {
              if (location.EntityHierarchy === localEntityHierarchy) {
                setSelectedEntity(localEntityHierarchy)
                return true
              }
            })
          }
        }
      }
    }
  }, [props.locations, props.isSSOFlow, props.selectionGrouping])

  useEffect(() => {
    const processSSO = async () => {
      try {
        setLoading(true)
        setAuthenticated(false)
        setEntityDetails(undefined)
        setProcessInstanceRefs(undefined)
        let shouldSetAuthenticatedToValue = true
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (!timeZone) {
          timeZone = tz.guess()
        }
        const response = await API.validateSSOUserDetails(selectedEntityHierarchy, timeZone)
        if (response.key !== OPERATION_KEYS.OPERATION_PROCESSED) {
          console.error(response)
          if (response.key === OPERATION_KEYS.OPERATION_FAILED) {
            const { validationFailure, previousScreening } = response.processingResponse
            if (
              validationFailure === VALIDATION_FAILURES.EMPLOYEE_DETAILS_INCORRECT ||
              validationFailure === VALIDATION_FAILURES.SCREENING_NOT_FOUND_FOR_DATE
            ) {
              if (validationFailure === VALIDATION_FAILURES.EMPLOYEE_DETAILS_INCORRECT && locations.length > 1) {
                shouldSetAuthenticatedToValue = false
                setSelectedEntity('')
                throw Error(intl.formatMessage({ id: VALIDATION_FAILURES.EMPLOYEE_DETAILS_INCORRECT_MULTILOCATION }))
              } else {
                throw Error(intl.formatMessage({ id: validationFailure }))
              }
            }
            if (validationFailure === VALIDATION_FAILURES.SCREENING_ALREADY_PROCESSED_ON_DATE) {
              //reprocessing not enabled - user has already submitted a screening
              if (previousScreening?.previousScreeningStatus === 'PASSED') {
                return handleSetInstructionsStatus('passed')
              } else {
                return handleSetInstructionsStatus('failed')
              }
            } else if (validationFailure === VALIDATION_FAILURES.FAILED_SCREENING_ON_DATE) {
              //reprocessing enabled - user has processed a screening on date and status was failed
              return handleSetInstructionsStatus('failed')
            } else if (validationFailure === VALIDATION_FAILURES.FAILED_SCREENING_ON_DATE_TRANSFERRED) {
              //either for reprocessing enabled or disabled the current date instance has a transferred failure
              // console.log(previousScreening?.previousProcessingTimeStamp) //this is the timestamp of date of previous cached failure -> should show something on final screen
              return handleSetInstructionsStatus('failed') //should show that this is a previous failure that is transferred e.g. slight variation of failed
            } else if (validationFailure === VALIDATION_FAILURES.SSO_IDENTIFIERS_MISSING) {
              return props.onSSOIdentifiersMissing()
            }
          } else if (response.key === OPERATION_KEYS.SSO_EXPIRED) {
            window.location.reload()
            notification.warn({
              message: 'Your session has expired',
              description: 'The page will reload automatically',
              duration: 3000,
            })
            return true
          }
          throw new Error('There was an issue looking up your employee record')
        }

        notification.open({
          message: intl.formatMessage({ id: 'notification.auth.success' }),
          className: 'bg-primary text-white',
        })

        const updatedIntl = setConfigI18NTexts(response.processingResponse.entityDetails.i18nTexts, locale)
        setIntl(updatedIntl)

        setEntityDetails(response?.processingResponse?.entityDetails)
        setProcessInstanceRefs({
          lmt: response?.processingResponse?.lmt || 0,
          processingToken: response?.processingResponse?.processingToken || '',
        })

        setLoading(false)
        setAuthenticated(shouldSetAuthenticatedToValue) //default to tue unless we have multi location and user selected a location where they don't exist
      } catch (error) {
        setLoading(false)

        notification.open({
          message: error.message,
          className: 'bg-error text-white',
        })
      }
    }
    handleResetInteractionTimer()
    // Set the selected hierarchy settings
    if (selectedEntityHierarchy) {
      props.onEntitySelect(selectedEntityHierarchy)
      if (props.isSSOFlow) {
        processSSO()
      }
    }
    // TODO: Ensure that deps are not required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntityHierarchy])

  const locations = useMemo(() => {
    //Filtering now happens at parent page based on EH
    // if (props.locations.length === 1) return props.locations
    // if (props.locations.length > 1) return props.locations.filter((_e, i) => i !== 0)
    return props.locations
  }, [props.locations])

  const screenFields = useMemo(
    () => (entityDetails ? getScreenFields(entityDetails.flowVariables, entityDetails.customFieldDefinitions) : []),
    [entityDetails]
  )

  useEffect(() => {
    if ((selectedEntityHierarchy && process.env.NODE_ENV === 'development') || process.env.ENV_DEV || process.env.STAGING) {
      form.setFields([
        { name: 'personalIdentificationNr', value: '00006000' },
        { name: 'alternatePersonalIdNr', value: '33333' },
        { name: 'organizationIdValue', value: '8405295261088' },
      ])
    }
    // TODO: Ensure that deps are not required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntityHierarchy])

  const handleResetInteractionTimer = useCallback(() => {
    if (props.kioskMode) {
      setInteractionTimer(0)
    }
  }, [props.kioskMode])

  const handleSetInstructionsStatus = useCallback((status) => {
    setInstructionStatus(status)
    const markCompleted = ['welcome', 'authentication', 'pre-screening'] as const
    setCompletedSteps((val) => [...val.filter((key) => !markCompleted.includes(key as any)), ...markCompleted])
    setCurrentStep(3)
    setFormState('isSubmitted')
  }, [])

  useEffect(() => {
    if (activeToken) {
      ;(async () => {
        try {
          handleResetInteractionTimer()

          const input = getInputElement('organization-id-value')
          if (input && input.type === 'text') {
            input.blur()
          }
          const response = await API.validateActiveToken(activeToken)

          if (response.key !== OPERATION_KEYS.OPERATION_PROCESSED) {
            console.error(response)
            if (response.key === OPERATION_KEYS.OPERATION_FAILED) {
              const { validationFailure, previousScreening } = response.processingResponse
              if (
                validationFailure === VALIDATION_FAILURES.EMPLOYEE_DETAILS_INCORRECT ||
                validationFailure === VALIDATION_FAILURES.SCREENING_NOT_FOUND_FOR_DATE
              ) {
                throw Error(intl.formatMessage({ id: validationFailure }))
              }
              if (validationFailure === VALIDATION_FAILURES.SCREENING_ALREADY_PROCESSED_ON_DATE) {
                //reprocessing not enabled - user has already submitted a screening
                if (previousScreening?.previousScreeningStatus === 'PASSED') {
                  return handleSetInstructionsStatus('passed')
                } else {
                  return handleSetInstructionsStatus('failed')
                }
              } else if (validationFailure === VALIDATION_FAILURES.FAILED_SCREENING_ON_DATE) {
                //reprocessing enabled - user has processed a screening on date and status was failed
                return handleSetInstructionsStatus('failed')
              } else if (validationFailure === VALIDATION_FAILURES.FAILED_SCREENING_ON_DATE_TRANSFERRED) {
                //either for reprocessing enabled or disabled the current date instance has a transferred failure
                // console.log(previousScreening?.previousProcessingTimeStamp) //this is the timestamp of date of previous cached failure -> should show something on final screen
                return handleSetInstructionsStatus('failed') //should show that this is a previous failure that is transferred e.g. slight variation of failed
              }
            }
            throw new Error('We could not seem to process your authentication request')
          }

          if (props.locations.findIndex((location) => location.EntityHierarchy === response.processingResponse.tokenHierarchy) > -1) {
            setSelectedEntity(response.processingResponse.tokenHierarchy)
            notification.open({
              message: intl.formatMessage({ id: 'notification.auth.success' }),
              className: 'bg-primary text-white',
            })

            setLoading(false)

            const updatedIntl = setConfigI18NTexts(response.processingResponse.entityDetails.i18nTexts, locale)
            setIntl(updatedIntl)

            setEntityDetails(response?.processingResponse?.entityDetails)
            setProcessInstanceRefs({
              lmt: response?.processingResponse?.lmt || 0,
              processingToken: response?.processingResponse?.processingToken || '',
            })

            setAuthenticated(true)
          } else {
            throw new Error('Channel mismatch')
          }
        } catch (error) {
          notification.open({
            message: error.message,
            className: 'bg-error text-white',
          })
        }
      })()
    }
    // TODO: Ensure that deps are not required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeToken])

  const handleAuthentication = async () => {
    try {
      handleResetInteractionTimer()
      const input = getInputElement('organization-id-value')
      if (input && input.type === 'text') {
        input.blur()
      }
      setLoading(true)
      const { orgValid, idValid } = await form
        .validateFields([['organizationIdValue'], !useAlternatePersonalId ? ['personalIdentificationNr'] : ['alternatePersonalIdNr']])
        .then((res) => {
          return {
            orgValid: Boolean(res.organizationIdValue),
            idValid: Boolean(res.personalIdentificationNr || res.alternatePersonalIdNr),
          }
        })

      if (!orgValid || !idValid) {
        if (!idValid)
          form.setFields([
            {
              name: [!useAlternatePersonalId ? 'personalIdentificationNr' : 'alternatePersonalIdNr'],
              errors: [
                intl.formatMessage({
                  id: entityFieldTexts[!useAlternatePersonalId ? 'personalIdentificationNrError' : 'alternatePersonalIdNrError'],
                }),
              ],
            },
          ])

        if (!orgValid) {
          form.setFields([
            {
              name: ['organizationIdValue'],
              errors: [intl.formatMessage({ id: entityFieldTexts.organizationIdError })],
            },
          ])
        }
        throw new Error(intl.formatMessage({ id: 'notification.auth.error' }))
      }
      const organizationIdValue = form.getFieldValue(['organizationIdValue'])?.toUpperCase()
      const idFieldValue = form
        .getFieldValue([!useAlternatePersonalId ? 'personalIdentificationNr' : 'alternatePersonalIdNr'])
        ?.toUpperCase()
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (!timeZone) {
        timeZone = tz.guess()
      }
      const response = await API.validateUserDetails(
        selectedEntityHierarchy,
        organizationIdValue,
        idFieldValue,
        timeZone,
        useAlternatePersonalId
      )

      if (response.key !== OPERATION_KEYS.OPERATION_PROCESSED) {
        console.error(response)
        if (response.key === OPERATION_KEYS.OPERATION_FAILED) {
          if (typeof window !== 'undefined' && localStorage.getItem('testAuthError')) {
            Sentry.captureException(new Error('Expected `processingResponse` in response from API on authentication, but it was missing.'))
          } else {
            if (response.processingResponse) {
              const { validationFailure, previousScreening } = response.processingResponse
              if (
                validationFailure === VALIDATION_FAILURES.EMPLOYEE_DETAILS_INCORRECT ||
                validationFailure === VALIDATION_FAILURES.SCREENING_NOT_FOUND_FOR_DATE
              ) {
                throw Error(intl.formatMessage({ id: validationFailure }))
              }
              if (validationFailure === VALIDATION_FAILURES.SCREENING_ALREADY_PROCESSED_ON_DATE) {
                //reprocessing not enabled - user has already submitted a screening
                if (previousScreening?.previousScreeningStatus === 'PASSED') {
                  return handleSetInstructionsStatus('passed')
                } else {
                  return handleSetInstructionsStatus('failed')
                }
              } else if (validationFailure === VALIDATION_FAILURES.FAILED_SCREENING_ON_DATE) {
                //reprocessing enabled - user has processed a screening on date and status was failed
                return handleSetInstructionsStatus('failed')
              } else if (validationFailure === VALIDATION_FAILURES.FAILED_SCREENING_ON_DATE_TRANSFERRED) {
                //either for reprocessing enabled or disabled the current date instance has a transferred failure
                // console.log(previousScreening?.previousProcessingTimeStamp) //this is the timestamp of date of previous cached failure -> should show something on final screen
                return handleSetInstructionsStatus('failed') //should show that this is a previous failure that is transferred e.g. slight variation of failed
              }
            } else {
              Sentry.captureException(
                new Error('Expected `processingResponse` in response from API on authentication, but it was missing.')
              )
            }
          }
        }
        throw new Error('We could not seem to process your authentication request')
      }

      notification.open({
        message: intl.formatMessage({ id: 'notification.auth.success' }),
        className: 'bg-primary text-white',
      })

      setLoading(false)

      const updatedIntl = setConfigI18NTexts(response.processingResponse.entityDetails.i18nTexts, locale)
      setIntl(updatedIntl)

      setEntityDetails(response?.processingResponse?.entityDetails)
      setProcessInstanceRefs({
        lmt: response?.processingResponse?.lmt || 0,
        processingToken: response?.processingResponse?.processingToken || '',
      })

      setAuthenticated(true)
      setCurrentStep(2)
      setCompletedSteps((val) => [...val, 'authentication'])
    } catch (error) {
      setLoading(false)
      setAuthError(error.message)
      // notification.open({
      //   message: error.message,
      //   duration: 7000,
      //   className: 'bg-error text-white',
      // })
    }
  }

  const handleFormFinish: FormProps['onFinish'] = async (data) => {
    try {
      handleResetInteractionTimer()
      if (formState == 'isSubmitted' || formState == 'isSubmitting') throw new Error('')
      setFormState('isSubmitting')
      const response = await API.submitScreening(processInstanceRefs, data)
      handleResetInteractionTimer()
      if (response.key !== OPERATION_KEYS.OPERATION_PROCESSED) {
        console.error(response)
        if (response.key === OPERATION_KEYS.OPERATION_FAILED) {
          const { validationFailure, previousScreeningStatus } = response.processingResponse
          if (validationFailure === VALIDATION_FAILURES.PROCESS_INSTANCE_OBSOLETE) {
            throw Error(intl.formatMessage({ id: validationFailure }))
          }
          if (validationFailure === VALIDATION_FAILURES.SCREENING_ALREADY_PROCESSED_ON_DATE) {
            if (props.kioskMode) {
              clearInterval(interactionTimerIntervalRef.current)
            }
            handleSetInstructionsStatus(previousScreeningStatus === 'FAILED' ? 'failed' : 'passed')

            throw Error()
          }
        } else if (response.key === OPERATION_KEYS.SSO_EXPIRED) {
          window.location.reload()
          notification.warn({
            message: 'Your session has expired',
            description: 'The page will reload automatically',
            duration: 7000,
          })
          return true
        }
        setFormState('')
        throw new Error('We could not seem to process your authentication request')
      } else {
        if (props.kioskMode) {
          clearInterval(interactionTimerIntervalRef.current)
        }
        const { updatedScreeningStatus } = response.processingResponse
        handleSetInstructionsStatus(updatedScreeningStatus === 'FAILED' ? 'failed' : 'passed')
      }
    } catch (error) {
      handleResetInteractionTimer()
      setFormState('')
      if (error.message) {
        setSubmitError(error.message)
        notification.open({
          message: error.message,
          className: 'bg-error text-white',
        })
      }
    }
  }

  const fieldClass = 'field mx-auto'

  const showFormFields = Boolean(
    (!props.isSSOFlow && !activeToken) || (activeToken && authenticated) || (props.isSSOFlow && locations.length > 1)
  )

  const shouldShowLoading = Boolean((activeToken && !authenticated) || (props.isSSOFlow && !authenticated && locations.length === 1))

  const shouldShowLocationSelector = !activeToken && locations.length > 1
  const shouldShowAuthentication =
    !props.isSSOFlow && !activeToken && (locations.length === 1 || (locations.length > 1 && !!selectedEntityHierarchy))
  const shouldShowAuthStep = shouldShowLocationSelector || shouldShowAuthentication

  const isPreScreen = props.step === 'prescreening'

  const AuthenticationTitle = () => {
    return (
      <Heading subHeading={props?.textOverrides?.authenticationSubTitle}>
        {props?.textOverrides?.authenticationTitle || intl.formatMessage({ id: 'lets-get-started' })}
      </Heading>
    )
  }

  const layout = {
    layout: 'vertical' as const,
  }

  const screeningDone = Boolean(instructionStatus)

  const Stepper = (stepperProps: StepsProps) => {
    const StepTitle = ({ children }) => <div className="truncate md:max-w-[75px] lg:max-w-[145px] xl:max-w-[180px]">{children}</div>
    const calculateCurrentActiveDisplayStep = (sourceStep) => {
      let calculatedStep = sourceStep
      if (!shouldShowAuthStep && calculatedStep > 1) {
        calculatedStep = calculatedStep - 1
      }
      return calculatedStep
    }

    const handleOnStepChange = (step) => {
      let calculatedStep = step
      if (!shouldShowAuthStep && calculatedStep === 1) {
        calculatedStep = 2
      }
      setCurrentStep(calculatedStep)
    }

    return (
      <Steps size="small" current={calculateCurrentActiveDisplayStep(currentStep)} onChange={handleOnStepChange} {...stepperProps}>
        <Steps.Step
          status={completedSteps.includes('welcome') ? 'finish' : 'process'}
          title={<StepTitle>{props?.textOverrides?.welcomeStepLabel || 'Welcome'}</StepTitle>}
          disabled={screeningDone}
        />
        {shouldShowAuthStep && (
          <Steps.Step
            status={completedSteps.includes('authentication') ? 'finish' : currentStep === 1 ? 'process' : 'wait'}
            title={<StepTitle>{props?.textOverrides?.authenticationStepLabel || 'Authentication'}</StepTitle>}
            disabled={!isPreScreen || screeningDone}
          />
        )}
        <Steps.Step
          status={completedSteps.includes('pre-screening') ? 'finish' : currentStep === 2 ? 'process' : 'wait'}
          title={<StepTitle>{props?.textOverrides?.preScreeningStepLabel || 'Pre-Screening'}</StepTitle>}
          disabled={!isPreScreen || !screenFields?.[0]}
        />
        <Steps.Step
          status={instructionStatus === 'failed' ? 'error' : instructionStatus === 'passed' ? 'finish' : 'wait'}
          title={<StepTitle>{props?.textOverrides?.doneStepLabel || 'Done'}</StepTitle>}
          disabled={!isPreScreen || !screenFields?.[0] || !screeningDone}
        />
      </Steps>
    )
  }

  const selectedLocation = props.locations.find((location) => location.EntityHierarchy === selectedEntityHierarchy)

  const shouldShowSubText = (fieldSubtext) => {
    return Boolean(fieldSubtext) && fieldSubtext !== 'field.empty'
  }

  return (
    <main>
      <Form
        form={form}
        onFinish={handleFormFinish}
        size="large"
        {...layout}
        onFieldsChange={() => {
          if (authError) setAuthError('')
          if (submitError) setSubmitError('')
          handleResetInteractionTimer()
        }}
        colon={false}
      >
        {shouldShowLoading ? <Loading /> : null}
        <div className="steps hidden md:flex items-center fixed backdrop-filter backdrop-blur bg-blur-light w-full top-0 left-0 z-40">
          <Logo src={props.welcomeViewProps.logo} alt={selectedLocation?.companyName || 'Pre-screening'} />
          <Stepper type="navigation" />
        </div>
        {showFormFields ? (
          <div>
            <div className="relative md:hidden max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10 backdrop-filter backdrop-blur bg-blur-light z-40">
              <Logo src={props.welcomeViewProps.logo} alt={selectedLocation?.companyName || 'Pre-screening'} />
              <b className="text-base">Step {currentStep + 1} / 4</b>
              <Dropdown
                trigger={['click', 'hover']}
                overlay={
                  <Menu>
                    <div className="p-4 -mb-6">
                      <Stepper direction="vertical" />
                    </div>
                  </Menu>
                }
              >
                <div className="z-50">
                  <Button icon={<UnorderedListOutlined />}>
                    <span className="sr-only">{'Show Steps'}</span>
                  </Button>
                </div>
              </Dropdown>
            </div>
            <div className="py-[56px]">
              {currentStep !== 0 ? null : (
                <div
                  className="container max-w-xl w-full flex place-items-center justify-center items-center pt-5 md:pt-40"
                  style={{ minHeight: 'calc(100vh - 100px)' }}
                >
                  <WelcomeView
                    {...props?.welcomeViewProps}
                    onAgreementContinue={() => {
                      props?.welcomeViewProps?.onAgreementContinue?.()
                      setCurrentStep(shouldShowAuthStep ? 1 : 2)
                      setCompletedSteps(['welcome'])
                    }}
                    onAgreementAcceptToggle={(checked) => {
                      props?.welcomeViewProps?.onAgreementAcceptToggle?.(checked)
                      if (!checked) {
                        setCurrentStep(0)
                        setCompletedSteps([])
                      }
                    }}
                    mandatoryAgreementRead={props?.welcomeViewProps?.mandatoryAgreementRead}
                    align="left"
                    buttonType="continueButton"
                    // disabled={completedSteps.includes('welcome')}
                  />
                </div>
              )}
              <div
                className={className('container max-w-xl w-full', {
                  'py-20 pt-5 md:pt-40': !screeningDone || (screeningDone && currentStep == 2),
                })}
              >
                {currentStep !== 1 ? null : (
                  <ul className="authentication-fields space-y-6 pl-0 ">
                    {shouldShowLocationSelector ? (
                      <div className={fieldClass} data-anchor={locationSlug}>
                        <AuthenticationTitle />
                        {props?.selectionGrouping ? (
                          <Form.Item label={intl.formatMessage({ id: 'field.label.location' })} required={true}>
                            <Form.Item name="locationSelection" hidden>
                              <Input />
                            </Form.Item>
                            <GroupedLocationSelection
                              selectionGrouping={props.selectionGrouping}
                              locations={locations}
                              onChange={(location) => {
                                setSelectedEntity(location)
                              }}
                              form={form}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name="locationSelection"
                            required={true}
                            label={intl.formatMessage({ id: 'field.label.location' })}
                            rules={[{ required: true, message: 'You need to select a location' }]}
                            initialValue={selectedEntityHierarchy || undefined}
                          >
                            <Select
                              id="locationSelection"
                              dropdownClassName="axe-ignore"
                              size="large"
                              value={selectedEntityHierarchy || undefined}
                              className={cn('max-w-full', touchDevice && !!selectedEntityHierarchy ? '' : '')}
                              placeholder={intl.formatMessage({ id: 'field.placeholder.location' })}
                              disabled={authenticated || loading}
                              onChange={(value) => {
                                setSelectedEntity(value as string)
                                return value
                              }}
                              dropdownMatchSelectWidth={false}
                            >
                              {locations.map((location) => (
                                <Select.Option key={location.EntityHierarchy} value={location.EntityHierarchy}>
                                  {`${location.companyName} - ${location.locationShortDescription}`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </div>
                    ) : null}
                    {shouldShowAuthentication ? (
                      <>
                        {!useAlternatePersonalId ? (
                          <div className={fieldClass} data-anchor={personalIDSlug}>
                            {locations.length === 1 && <AuthenticationTitle />}
                            <Form.Item
                              id={`input-${personalIDSlug}`}
                              name="personalIdentificationNr"
                              label={intl.formatMessage({ id: entityFieldTexts.personalIdentificationNrLabel })}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    intl.formatMessage({ id: entityFieldTexts.personalIdentificationNrError }) || 'This field is required',
                                },
                              ]}
                              normalize={(value) => (value || '').toUpperCase()}
                              {...(shouldShowSubText(entityFieldTexts.personalIdentificationNrSubText) && {
                                extra: intl.formatMessage({ id: entityFieldTexts.personalIdentificationNrSubText }),
                              })}
                              className={config.suppressClass}
                            >
                              <Input
                                placeholder={intl.formatMessage({ id: entityFieldTexts.personalIdentificationNrPlaceholder })}
                                disabled={authenticated || loading}
                              />
                            </Form.Item>
                          </div>
                        ) : (
                          <div className={fieldClass} data-anchor={alternatePersonalIdNrSlug}>
                            {locations.length === 1 && <AuthenticationTitle />}
                            <Form.Item
                              id={`input-${alternatePersonalIdNrSlug}`}
                              name="alternatePersonalIdNr"
                              label={intl.formatMessage({ id: entityFieldTexts.alternatePersonalIdNrLabel })}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    intl.formatMessage({ id: entityFieldTexts.alternatePersonalIdNrError }) || 'This field is required',
                                },
                              ]}
                              normalize={(value) => (value || '').toUpperCase()}
                              {...(shouldShowSubText(entityFieldTexts.alternatePersonalIdNrSubText) && {
                                extra: intl.formatMessage({ id: entityFieldTexts.alternatePersonalIdNrSubText }),
                              })}
                              className={config.suppressClass}
                            >
                              <Input
                                placeholder={intl.formatMessage({ id: entityFieldTexts.alternatePersonalIdNrPlaceholder })}
                                disabled={authenticated || loading}
                              />
                            </Form.Item>
                          </div>
                        )}
                        <div className={fieldClass} data-anchor={orgIDSlug}>
                          <Form.Item
                            id={`input-${orgIDSlug}`}
                            name="organizationIdValue"
                            label={intl.formatMessage({ id: entityFieldTexts.organizationIdLabel })}
                            rules={[{ required: true, message: 'This field is required' }]}
                            normalize={(value) => (value || '').toUpperCase()}
                            {...(shouldShowSubText(entityFieldTexts.organizationIdSubText) && {
                              extra: intl.formatMessage({ id: entityFieldTexts.organizationIdSubText }),
                            })}
                            className={config.suppressClass}
                          >
                            <Input
                              placeholder={intl.formatMessage({ id: entityFieldTexts.organizationIdPlaceholder })}
                              disabled={authenticated || loading}
                            />
                          </Form.Item>
                        </div>
                      </>
                    ) : null}

                    {authError && <Alert type="error" showIcon={false} message={authError} banner />}
                    {!authenticated ? (
                      <Button onClick={handleAuthentication} loading={loading} type="primary">
                        Authenticate
                      </Button>
                    ) : (
                      <div className="flex space-x-2 items-center justify-between">
                        <div className="text-success flex space-x-2 items-center">
                          <ThumbsUp />
                          <h4 className="mb-0 text-[inherit]">{intl.formatMessage({ id: 'field.validation.identity-confirmed' })}</h4>
                        </div>
                        <Button
                          onClick={() => {
                            setCurrentStep((step) => step + 1)
                          }}
                          type="primary"
                        >
                          Continue
                        </Button>
                      </div>
                    )}
                  </ul>
                )}

                {currentStep !== 2 ? null : (
                  <>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldsValue }) => {
                        return (
                          <DynamicPreScreeningFields
                            {...{
                              screenFields: screenFields,
                              fieldClass,
                              isSubmitting: formState == 'isSubmitting',
                              loading,
                              disabled: formState === 'isSubmitting' || formState === 'isSubmitted' || screeningDone,
                              values: getFieldsValue(),
                              form,
                              headingText: props?.textOverrides?.preScreeningTitle,
                              subHeadingText: props?.textOverrides?.prescreeningSubTitle,
                            }}
                          />
                        )
                      }}
                    </Form.Item>

                    {submitError && <Alert type="error" showIcon={false} message={submitError} banner className="mb-2" />}

                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={formState === 'isSubmitting'}
                      disabled={formState === 'isSubmitted'}
                    >
                      <FormattedMessage id="submit" tagName="span" />
                    </Button>
                  </>
                )}
                {screeningDone &&
                  currentStep !== 2 &&
                  (props.instructionsProps?.doneScreenHintSet === 'NEDBANK' ? (
                    <NedbankInstructionsView {...props.instructionsProps} status={instructionStatus} />
                  ) : (
                    <InstructionsView {...props.instructionsProps} status={instructionStatus} />
                  ))}
              </div>
            </div>
          </div>
        ) : null}
        <div className="hidden md:block">
          <LocaleSwitcher fixed />
        </div>
      </Form>
      <style jsx global>{`
        .ant-form-item-label > label {
          @apply h-auto items-baseline !important;
        }
      `}</style>
    </main>
  )
}

const DynamicPreScreeningFields = (props: {
  screenFields: ScreenField[]
  fieldClass?: string
  isSubmitting?: boolean
  loading?: boolean
  values?: any
  form: FormInstance
  disabled?: boolean
  headingText?: ReactNode
  subHeadingText?: ReactNode
}) => {
  const { screenFields = [] as ScreenField[], fieldClass, isSubmitting, loading, values, form } = props
  const intl = useIntl()

  const renderFields = useMemo(() => {
    const fields = screenFields
      .map((field, index) => {
        const { isConditionalVisible, isConditionalEditable } = filterProcessor(field.rootCondition, values)

        if (!isConditionalVisible) return undefined
        const label = intl.formatMessage({ id: field.labelTextId })
        const slug = slugify(label)
        const disabled = !isConditionalEditable || props.disabled
        const required = field.inputSettings.mandatory
        const placeholder = field.inputSettings.inputPlaceholderTextId
          ? intl.formatMessage({ id: field.inputSettings.inputPlaceholderTextId })
          : ''
        return {
          index,
          label,
          slug,
          disabled,
          required,
          placeholder,
          field,
        }
      })
      .filter(Boolean)
    return fields
  }, [screenFields, values, intl, props.disabled])

  return (
    <>
      {props.headingText && <Heading subHeading={props?.subHeadingText}>{props?.headingText}</Heading>}
      <ul className="dynamic-fields list-none h-full space-y-6 pl-0">
        {renderFields.map(({ field, index, slug, label, placeholder, required, disabled }) => {
          const sharedFieldProps = {
            label,
            name: field.fieldId,
            rules: [{ required, message: 'This field is required' }],
          }
          const sharedInputProps = {
            disabled,
            placeholder,
          }

          return (
            <li key={`${field.fieldId}-${index}`} className={fieldClass} data-anchor={slug}>
              {field.inputSettings.inputType === 'staticDropDownListMultiSelect' && (
                <Form.Item {...sharedFieldProps}>
                  <SelectGrouped
                    {...sharedInputProps}
                    placeholder={placeholder}
                    options={field.staticDropDownListItems.map((option) => ({
                      value: option.itemId,
                      label: intl.formatMessage({ id: option.optionTextId }),
                      itemGroupings: option.itemGroupings,
                    }))}
                    multiple
                    name={field.fieldId}
                    setFields={form.setFields}
                    getFieldValue={form.getFieldValue}
                    loading={isSubmitting || loading}
                  />
                </Form.Item>
              )}
              {field.inputSettings.inputType === 'staticDropDownListSingleSelect' && (
                <Form.Item {...sharedFieldProps}>
                  <Radio.Group disabled={disabled}>
                    {field.staticDropDownListItems.map((option) => (
                      <Radio key={option.itemId} value={option.itemId}>
                        {intl.formatMessage({ id: option.optionTextId })}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}
              {field.inputSettings.inputType === 'text' && (
                <Form.Item {...sharedFieldProps}>
                  <Input {...sharedInputProps} />
                </Form.Item>
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

type GroupedLocationSelectionProps = Pick<GroupedPreScreeningProps, 'selectionGrouping' | 'locations'> & {
  onChange: (value: string) => void
  form: FormInstance
}

// eslint-disable-next-line react/display-name
const GroupedLocationSelection = memo((props: GroupedLocationSelectionProps) => {
  // eslint-disable-next-line react/display-name
  const GroupedSelect = memo((p: { options: typeof props['selectionGrouping']['children']; name: string[]; placeholder?: string }) => {
    const name = p.name.map((name) => slugify(name))

    const createName = (key: string) => {
      return ['location', slugify(key)]
    }

    const getLocationName = (possibleHierarchy: string) => {
      const location = props.locations.find((location) => location.EntityHierarchy === possibleHierarchy)
      if (location) return `${location.companyName} - ${location.locationShortDescription}`
      return possibleHierarchy
    }

    const getSelectedProps = useCallback(
      (selectedValue: string) => {
        const selectedOption = p?.options?.find((option) => option.description === selectedValue)
        const selectedHasChildren = selectedOption?.children?.[0]
        const isLocation = props.locations.some((location) => location.EntityHierarchy === selectedValue)

        return {
          selectedOption,
          selectedHasChildren,
          isLocation,
        }
      },
      [p?.options]
    )

    return (
      <div className="space-y-4">
        <Form.Item name={name} noStyle>
          <Select
            placeholder={p?.placeholder}
            onChange={(selectedValue) => {
              const { selectedOption, isLocation, selectedHasChildren } = getSelectedProps(selectedValue as string)
              if (isLocation) {
                props.form.setFields([{ name: 'locationSelection', value: selectedValue }])
                props.onChange(selectedOption.description)
              } else if (selectedHasChildren && selectedOption?.children) {
                props.form.setFields(selectedOption.children.map((child) => ({ name: createName(child.description), value: undefined })))
                props.onChange(undefined)
              }
            }}
          >
            {p.options.map((option) => (
              <Select.Option key={option.description} value={option.description}>
                {getLocationName(option.description)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item dependencies={[name]} noStyle>
          {({ getFieldValue }) => {
            const selectedValue = getFieldValue(name)
            const { selectedOption, selectedHasChildren } = getSelectedProps(selectedValue as string)
            if (selectedHasChildren) {
              return (
                <GroupedSelect
                  key={selectedOption.description}
                  options={selectedOption.children}
                  name={createName(selectedOption.description)}
                  placeholder={selectedOption?.childPlaceHolder}
                />
              )
            } else {
              return null
            }
          }}
        </Form.Item>
      </div>
    )
  })

  return (
    <GroupedSelect
      options={props.selectionGrouping.children}
      name={['location', 'topLevel']}
      placeholder={props.selectionGrouping.childPlaceHolder}
    />
  )
})

const Logo = ({ alt, src }) => {
  const [url, setUrl] = useState('/')

  useEffect(() => {
    if (typeof window !== 'undefined') setUrl(window.location.origin)
  }, [])

  return (
    <a href={url} className="flex px-4 max-w-[100px]">
      <span className="sr-only">{alt}</span>
      <img className="max-h-16 w-full object-contain" src={src} alt={alt} />
    </a>
  )
}
