// import { WSASERVICE_NOT_FOUND } from "constants";
export enum API_NAMES {
  PRE_SCREENING_OPERATIONS = 'preScreeningOperations',
}

export enum PROCESSORS {
  PRE_SCREENING_APP_PROCESSOR = 'preScreeningAppProcessor',
}

export enum OPERATIONS {
  GET_CHANNEL_CONFIG = 'getChannelConfig',
  GET_ACCESS_TOKEN_FOR_SSO_AUTH_CODE = 'getAccessTokenForSSOAuthCode',
  VALIDATE_USER_DETAILS = 'validateUserDetails',
  VALIDATE_SSO_USER_DETAILS = 'validateSSOUserDetails',
  VALIDATE_ACTIVE_TOKEN = 'validateActiveToken',
  SUBMIT_SCREENING = 'submitScreening',
}

export enum OPERATION_KEYS {
  OPERATION_PROCESSED = 'OPERATION_PROCESSED', // statusCode: 2xx
  OPERATION_FAILED = 'OPERATION_FAILED', // validation failure string  // statusCode: 2xx
  GENERIC_ERROR = 'GENERIC_ERROR', // statusCode: > 2xx
  SSO_EXPIRED = 'SSO_EXPIRED',
}

export enum VALIDATION_FAILURES {
  EMPLOYEE_DETAILS_INCORRECT = 'EMPLOYEE_DETAILS_INCORRECT', // invalid employee details [perID, ordID]
  EMPLOYEE_DETAILS_INCORRECT_MULTILOCATION = 'EMPLOYEE_DETAILS_INCORRECT_MULTILOCATION', //for SSO multi location incorrect location selection
  SCREENING_NOT_FOUND_FOR_DATE = 'SCREENING_NOT_FOUND_FOR_DATE',
  FAILED_SCREENING_ON_DATE = 'FAILED_SCREENING_ON_DATE',
  FAILED_SCREENING_ON_DATE_TRANSFERRED = 'FAILED_SCREENING_ON_DATE_TRANSFERRED',
  SCREENING_ALREADY_PROCESSED_ON_DATE = 'SCREENING_ALREADY_PROCESSED_ON_DATE',
  PROCESS_INSTANCE_OBSOLETE = 'PROCESS_INSTANCE_OBSOLETE',
  SSO_IDENTIFIERS_MISSING = 'SSO_IDENTIFIERS_MISSING',
}

export enum FIELD_CONDITION_COMPARISON_OPERATORS {
  EQ = 'EQ',
  NE = 'NE',
  GT = 'GT',
  LT = 'LT',
  GE = 'GE',
  LE = 'LE',
  BT = 'BT',
  CONTAINS = 'CONTAINS',
  NOTCONTAINS = 'NOTCONTAINS',
}

export enum FIELD_CONDITION_JOIN_OPERATORS {
  AND = 'AND',
  OR = 'OR',
}

const enums = {
  API_NAMES,
  PROCESSORS,
  OPERATIONS,
  OPERATION_KEYS,
  VALIDATION_FAILURES,
  FIELD_CONDITION_COMPARISON_OPERATORS,
  FIELD_CONDITION_JOIN_OPERATORS,
}

export default enums
