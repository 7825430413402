import { HTMLAttributes, ReactNode } from 'react'
import cn from 'utils/classnames'
import SubHeading from './sub-heading'

interface HeadingProps extends HTMLAttributes<HTMLDivElement> {
  subHeading?: ReactNode
}

export default function Heading({ subHeading, className = '', ...props }: HeadingProps) {
  return (
    <div
      className={cn('heading-component', className, {
        'mb-[32px]': !className?.includes?.('mb-'),
      })}
      {...props}
    >
      <h1 className="mb-0">{props.children}</h1>
      {subHeading && <SubHeading>{subHeading}</SubHeading>}
    </div>
  )
}
