import { Button, FormInstance, Select, SelectProps, Divider, Tag } from 'antd'
import { LabeledValue, RefSelectProps } from 'antd/lib/select'
import { useEffect } from 'react'
import { useCombinedRefs } from 'hooks/useCombinedRefs'
import { NamePath } from 'rc-field-form/lib/interface'
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import handleSelectWithGroupings from 'utils/handleSelectWithGroupings'
import { useCallback } from 'react'

type SelectGroupedProps = Omit<SelectProps<any>, 'options'> & {
  options: Array<LabeledValue & { itemGroupings: number[] }>
  multiple: true
  name: NamePath
  getFieldValue: FormInstance['getFieldValue']
  setFields: FormInstance['setFields']
}

const validateComponentProps = (props: SelectGroupedProps) => {
  if (props.multiple) {
    if (!props.name) throw new Error('Missing field name prop')
    if (!props.getFieldValue) throw new Error('Missing getFieldValue prop')
    if (!props.setFields) throw new Error('Missing setFields prop')
  }
}

const SelectGrouped: React.ForwardRefRenderFunction<RefSelectProps, SelectGroupedProps> = ({ open, ...props }, ref) => {
  validateComponentProps(props)
  const { name, getFieldValue, setFields, options, ...selectProps } = props
  const [visible, setVisible] = React.useState(false)
  const [currentSelection, setCurrentSelection] = React.useState([])
  const intl = useIntl()

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (visible) {
      const fieldValue = getFieldValue(name)
      setCurrentSelection(fieldValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const selectRef = useCombinedRefs<RefSelectProps>(ref)

  const handleChange = useCallback(
    (value) => {
      setFields([
        {
          name,
          value,
        },
      ])
    },
    [name, setFields]
  )

  const handleSelect: typeof props.onChange = (value) => {
    const fieldValue = getFieldValue(name)

    const { nextValue } = handleSelectWithGroupings(fieldValue, props.options, value)

    handleChange(nextValue)
  }

  const handleCancel = useCallback(() => {
    handleChange(currentSelection)
    setVisible(false)
  }, [currentSelection, handleChange])

  const handleSelectAll = useCallback(() => {
    handleChange(props.options.filter((item) => !item.itemGroupings).map((option) => option.value))
  }, [handleChange, props.options])

  const handleClear = useCallback(() => {
    handleChange([])
  }, [handleChange])

  return (
    <>
      <Select
        {...selectProps}
        options={options.map((option) => ({
          value: option.value,
          label: option.label,
        }))}
        ref={selectRef}
        mode={props.multiple ? 'multiple' : undefined}
        placeholder={
          props.placeholder || intl.formatMessage({ id: props.multiple ? 'field.selectmulti.placeholder' : 'field.select.placeholder' })
        }
        showSearch={false}
        dropdownClassName="allow-scroll"
        onSelect={handleSelect}
        showArrow
        onDropdownVisibleChange={setVisible}
        open={visible}
        dropdownRender={(menu) => (
          <div>
            <div className="flex justify-between items-center px-4 py-2">
              <Button type="link" onClick={() => handleSelectAll()} className="border-none shadow-none px-0 hover:opacity-75" size="small">
                Select All
              </Button>
              <Button type="link" onClick={() => handleClear()} className="border-none shadow-none px-0 hover:opacity-75" size="small">
                Clear
              </Button>
            </div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div className="flex justify-between items-center px-4">
              <div className="text-sm text-gray-400">
                {getFieldValue(name)?.length ?? 0} of {options.length}
              </div>
              <div className="flex space-x-2">
                <Button type="primary" onClick={() => handleCancel()} ghost className="border-none shadow-none">
                  Cancel
                </Button>
                <Button type="primary" onClick={() => setVisible(false)}>
                  Done
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  )
}

export default React.memo(forwardRef(SelectGrouped))
