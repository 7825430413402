import enums, { VALIDATION_FAILURES } from 'typings/kenai/enums'
import * as interfaces from 'typings/kenai/interfaces'
import { mapValues, isArray } from 'lodash'
import awsexports from 'lib/aws-exports'

const fetchEnvironment = (lookupValue) => {
  return new Promise((resolve) => {
    try {
      const envLookup = `screening-${process.env.ENV_QUALIFIER}-${lookupValue}`
      fetch(`https://envdiscovery.kenai.co.za/${envLookup}.json?x=${Date.now()}`)
        .then((res) => {
          return res.json()
        })
        .then((environment) => {
          resolve(environment)
        })
        .catch(() => {
          const envLookupFallback = `screening-${process.env.ENV_QUALIFIER}-default`
          fetch(`https://envdiscovery.kenai.co.za/${envLookupFallback}.json?x=${Date.now()}`)
            .then((res) => {
              return res.json()
            })
            .then((environment) => {
              resolve(environment)
            })
            .catch((e) => {
              console.error(e)
              resolve(false)
            })
        })
    } catch (e) {
      console.error(e)
      resolve(false)
    }
  })
}

/** e.g. `https://__ENV__.execute-api.eu-west-1.amazonaws.com/LATEST` */
const getApiEndpoint = (environment: any, apiName: string): string => {
  let endpoint = ''
  awsexports.aws_cloud_logic_custom.forEach((api) => {
    if (api.name === apiName) endpoint = `${api.endpoint}`
  })
  Object.keys(environment).forEach((key) => {
    endpoint = endpoint.replace(new RegExp(`__${key}__`, 'g'), environment[key])
  })
  return endpoint
}

export interface APIResponse<T> {
  key: keyof typeof enums.OPERATION_KEYS
  processingResponse?: T & {
    validationFailure?: VALIDATION_FAILURES
  }
}

/** Returns the relevant api endpoint:
 *  @frontend   https://__ENV__.kenai.co.za/api/v1
 *  @backend    https://__ENV__.execute-api.eu-west-1.amazonaws.com/LATEST
 */
export const getEndpoint = (environment?: any) => {
  if (typeof window !== 'undefined') {
    return `${window.location.origin}/api/v1`
  } else {
    return getApiEndpoint(environment, enums.API_NAMES.PRE_SCREENING_OPERATIONS)
  }
}

export type getChannelConfigResponse = APIResponse<{ channelConfig: interfaces.CHANNEL_CONFIG }>
function getChannelConfig(lookupValue: string, envRetrieved: any): Promise<getChannelConfigResponse> {
  return new Promise((resolve, reject) => {
    fetch(`${getEndpoint(envRetrieved)}/${enums.PROCESSORS.PRE_SCREENING_APP_PROCESSOR}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        processingRequest: {
          operation: enums.OPERATIONS.GET_CHANNEL_CONFIG,
          lookupValue,
        },
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((jsonResponse) => resolve(jsonResponse))
      .catch((e) => reject(e))
  })
}

export type getActiveTokenForSSOAuthCodeResponse = APIResponse<{ authToken: string }>
function getAccessTokenForSSOAuthCode(
  authCode: string,
  lookupValue: string,
  envRetrieved: any
): Promise<getActiveTokenForSSOAuthCodeResponse> {
  return new Promise((resolve, reject) => {
    // const endpoint = getApiEndpoint(enums.API_NAMES.PRE_SCREENING_OPERATIONS)
    fetch(`${getEndpoint(envRetrieved)}/${enums.PROCESSORS.PRE_SCREENING_APP_PROCESSOR}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        processingRequest: {
          operation: enums.OPERATIONS.GET_ACCESS_TOKEN_FOR_SSO_AUTH_CODE,
          authCode,
          lookupValue,
        },
      }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => resolve(jsonResponse))
      .catch((e) => reject(e))
  })
}

export type ValidateUserDetailsResponse = APIResponse<{
  lmt?: number
  processingToken?: string
  entityDetails?: {
    customFieldDefinitions: interfaces.CUSTOM_FIELDS[]
    flowVariables: {
      customFields: interfaces.FLOW_CONFIG_CUSTOM_FIELDS[]
      fieldConditions: interfaces.FLOW_CONFIG_FIELD_CONDITIONS
      standardFields: any
    }
    i18nTexts: interfaces.ConfigValueI18NText[]
  }
  previousScreening?: {
    previousScreeningStatus?: 'FAILED' | 'PASSED' | 'INCOMPLETE'
    previousProcessingTimeStamp?: number
  }
}>

function validateUserDetails(
  EntityHierarchy: string,
  organizationIdValue: string,
  idFieldValue: string,
  clientTZ: string,
  useAlternatePersonalId: boolean
): Promise<ValidateUserDetailsResponse> {
  return new Promise((resolve, reject) => {
    // const endpoint = getApiEndpoint(enums.API_NAMES.PRE_SCREENING_OPERATIONS)
    fetch(`${getEndpoint()}/${enums.PROCESSORS.PRE_SCREENING_APP_PROCESSOR}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        processingRequest: {
          operation: enums.OPERATIONS.VALIDATE_USER_DETAILS,
          EntityHierarchy,
          clientTZ,
          userDetails: {
            ...(!useAlternatePersonalId ? { personalIdentificationNr: idFieldValue } : {}),
            ...(useAlternatePersonalId ? { alternatePersonalIdNr: idFieldValue } : {}),
            organizationIdValue,
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => resolve(jsonResponse))
      .catch((e) => reject(e))
  })
}

function validateSSOUserDetails(EntityHierarchy: string, clientTZ: string): Promise<ValidateUserDetailsResponse> {
  return new Promise((resolve, reject) => {
    // const endpoint = getApiEndpoint(enums.API_NAMES.PRE_SCREENING_OPERATIONS)
    fetch(`${getEndpoint()}/${enums.PROCESSORS.PRE_SCREENING_APP_PROCESSOR}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        processingRequest: {
          operation: enums.OPERATIONS.VALIDATE_SSO_USER_DETAILS,
          EntityHierarchy,
          clientTZ,
        },
      }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => resolve(jsonResponse))
      .catch((e) => reject(e))
  })
}

export type ValidateActiveTokenResponse = APIResponse<{
  tokenHierarchy: string
  lmt: number
  processingToken: string
  entityDetails: {
    customFieldDefinitions: interfaces.CUSTOM_FIELDS[]
    flowVariables: {
      customFields: interfaces.FLOW_CONFIG_CUSTOM_FIELDS[]
      fieldConditions: interfaces.FLOW_CONFIG_FIELD_CONDITIONS
      standardFields: any
    }
    i18nTexts: interfaces.ConfigValueI18NText[]
  }
  previousScreening?: {
    previousScreeningStatus?: 'FAILED' | 'PASSED' | 'INCOMPLETE'
    previousProcessingTimeStamp?: number
  }
}>

function validateActiveToken(activeToken: string): Promise<ValidateActiveTokenResponse> {
  return new Promise((resolve, reject) => {
    // const endpoint = getApiEndpoint(enums.API_NAMES.PRE_SCREENING_OPERATIONS)
    fetch(`${getEndpoint()}/${enums.PROCESSORS.PRE_SCREENING_APP_PROCESSOR}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        processingRequest: {
          operation: enums.OPERATIONS.VALIDATE_ACTIVE_TOKEN,
          activeToken,
        },
      }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => resolve(jsonResponse))
      .catch((e) => reject(e))
  })
}

export type SubmitScreeningResponse = APIResponse<{
  submissionStatus: 'SCREENING_SUBMITTED'
  previousScreeningStatus?: 'FAILED' | 'PASSED' | 'INCOMPLETE'
}>

function submitScreening(
  processInstanceRefs: Pick<ValidateUserDetailsResponse['processingResponse'], 'lmt' | 'processingToken'>,
  sourceValues: Record<string, string>
): Promise<any | SubmitScreeningResponse> {
  return new Promise((resolve, reject) => {
    const { personalIdentificationNr, alternatePersonalIdNr, organizationIdValue, ...submissionValues } = sourceValues
    // const endpoint = getApiEndpoint(enums.API_NAMES.PRE_SCREENING_OPERATIONS)
    fetch(`${getEndpoint()}/${enums.PROCESSORS.PRE_SCREENING_APP_PROCESSOR}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        processingRequest: {
          operation: enums.OPERATIONS.SUBMIT_SCREENING,
          processInstanceRefs,
          submissionValues: mapValues(submissionValues, (submissionValue) => {
            if (submissionValue && isArray(submissionValue)) {
              return submissionValue.join(';')
            } else {
              return submissionValue
            }
          }),
        },
      }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => resolve(jsonResponse))
      .catch((e) => reject(e))
  })
}

const API = {
  fetchEnvironment,
  getChannelConfig,
  getAccessTokenForSSOAuthCode,
  validateUserDetails,
  validateSSOUserDetails,
  validateActiveToken,
  submitScreening,
}

export default API
