import * as interfaces from 'typings/kenai/interfaces'

const stableSort = (arr, compare) =>
  arr
    .map((item, index) => ({ item, index }))
    .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item)

export type ScreenField = Omit<interfaces.CUSTOM_FIELDS, 'EntityHierarchy'>

const getScreenFields = (flowVariables, customFieldDefinitions) => {
  const screenFields: ScreenField[] = []
  const customFieldkeys = Object.keys(flowVariables.customFields)
  for (const customFieldId of customFieldkeys) {
    const customFieldDef = customFieldDefinitions.find((customField) => customField.fieldId === customFieldId)
    if (customFieldDef) {
      const flowCustomFieldConfig = flowVariables.customFields[customFieldId]
      if (customFieldDef.disabled !== true && flowCustomFieldConfig.disabled !== true) {
        const customScreenField: ScreenField = {
          fieldId: customFieldDef.fieldId,
          labelTextId: customFieldDef.labelTextId,
          deleted: customFieldDef.deleted,
          disabled: customFieldDef.disabled,
          inputSettings: {
            ...customFieldDef.inputSettings,
            ...flowCustomFieldConfig.inputSettingOverrides,
          },
          ...(customFieldDef.staticDropDownListItems && {
            staticDropDownListItems: customFieldDef.staticDropDownListItems
              .filter((item) => item.disabled !== true)
              .sort((a, b) => a.sortIndex - b.sortIndex),
          }),
          sortIndex: flowCustomFieldConfig.sortIndex,
          rootCondition: flowVariables.fieldConditions[customFieldDef.fieldId],
        }
        screenFields.push(customScreenField)
      }
    }
  }
  const stableSortedResult: ScreenField[] = stableSort(screenFields, (a, b) => a.sortIndex - b.sortIndex)
  const sortTracker = {
    sourceSortIndex: -1,
    targetSortIndex: -1,
  }
  stableSortedResult.forEach((screenField) => {
    if (sortTracker.sourceSortIndex !== screenField.sortIndex) {
      sortTracker.sourceSortIndex = screenField.sortIndex
      sortTracker.targetSortIndex++
    }
    screenField.sortIndex = sortTracker.targetSortIndex
  })
  return stableSortedResult
}

export default getScreenFields
